import React from "react";

import TextBox from "@components/TextBox";
import Section from "@components/Section";
import Hero from "@components/Hero";

import * as styles from "./styles.module.css";

const GenericPage = ({
  title,
  backgroundImage,
  intro,
  backgroundColor,
  _rawContent,
}) => {
  return (
    <>
      <Hero
        {...{
          title,
          backgroundImage,
          className: styles.hero,
        }}
      >
        <TextBox {...intro} hyphenate className={styles.box} />
      </Hero>

      <Section
        as="section"
        className={styles.contentWrapper}
        backgroundColor={backgroundColor?.value}
      >
        <TextBox
          whiteBox={false}
          className={styles.content}
          {...{ _rawContent }}
        />
      </Section>
    </>
  );
};

export default GenericPage;
