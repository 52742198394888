import { graphql } from "gatsby";
import React from "react";

import GenericPage from "@components/GenericPage";

const GenericPageTemplate = ({ data }) => {
  const { pageData } = data;

  return <GenericPage {...pageData} />;
};

export const query = graphql`
  query GenericPageTemplateQuery($id: String!) {
    pageData: sanityGenericPage(id: { eq: $id }) {
      id
      _rawContent(resolveReferences: { maxDepth: 10 })
      title
      backgroundImage {
        ...SanityBackgroundImageFragment
      }
      backgroundColor {
        value
      }
      intro {
        ...SanityTextBoxFragment
      }
    }
  }
`;

export default GenericPageTemplate;
